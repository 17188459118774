import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { Link } from 'gatsby'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Third party
import { motion, AnimatePresence } from 'framer-motion'

const Block = styled(Link)`
  background-color: ${(props) => props.theme.color.light};
  color: ${(props) => props.theme.color.secondary};
  border-radius: 25px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;

  & img {
    height: 70px;
  }

  &:hover {
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: underline;
  }
`

interface TextWithBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithBlocks
  location?: any
}

const ContentWrapper = styled.div`
  @media screen and (max-width: 991px) {
    & p {
      margin-bottom: 0;
    }
  }
`

const BlockName = styled.span`
  line-height: 20px;
`

const TextWithBlocks: React.FC<TextWithBlocksProps> = ({ fields }) => (
  <section className="mt-5">
    <div className="container py-lg-5 py-4">
      <div className="row">
        <ContentWrapper className="col-lg-5 mb-5 mb-lg-0">
          <ParseContent content={fields?.description} />
        </ContentWrapper>

        <div className="col-lg-7 d-flex align-items-center justify-content-center">
          <div className="row w-100">
            {fields.blocks?.map((block, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-sm-4 col-6 mb-4 mb-md-0" key={index}>
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, width: 0 }}
                    animate={{ opacity: 1, width: '100%' }}
                    exit={{ opacity: 0, width: 0 }}
                    transition={{ type: 'spring', stiffness: 100 }}
                  >
                    <Block
                      to={block?.block?.link?.url || '/'}
                      className="d-flex flex-column align-items-center px-4 pt-5 pb-4"
                    >
                      <Plaatjie image={block?.block?.icon} alt="Icoon" />
                      <BlockName className="mt-auto pt-4 text-center">
                        {block?.block?.title}
                      </BlockName>
                    </Block>
                  </motion.div>
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextWithBlocks
